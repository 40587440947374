import React from "react"
import Footer from "../../components/Footer/Footer";

const styles = require("../../styles/pages/General.module.scss");

export default function Gdpr() {
    return (
        <div>
            <section>
                <img src="/assets/eu-flags.png" alt="European Union Flags"/>

                <h1>Prestavi is GDPR compliant.</h1>
                <p>Prestavi is committed to compliance with the General Data Protection Regulation (GDPR). Your privacy rights are very important to us. We’ve devoted significant resources towards our efforts to comply with the GDPR. </p>

                <h2>How we've prepared</h2>

                <div className={styles.Checklist}>
                    <p>We created internal policies for the protection of personal data within Prestavi.</p>
                    <p>All team members receive training regarding data protection and the GDPR.</p>
                    <p>All team members are subject to confidentiality obligations concerning personal data.</p>
                    <p>We identified the personal data that we process and established the lawful basis of processing under the GDPR.</p>
                    <p>We updated our <a href="/legal/privacy">Privacy Policy</a> and <a href="/legal/terms">Terms of Service</a> to reflect our compliance with the GDPR. </p>
                    <p>Our support team is able and ready to fulfill data subject requests promptly.</p>
                    <p>As a controller, we’ve put in place data processing contracts with processors.</p>
                    <p>By default, we plan for data protection in new or changed services and systems.</p>
                    <p>Support for international data transfers by executing Standard Contractual Clauses through our Data Processing Addendum.</p>
                    <p>We monitor the guidance around GDPR compliance from regulatory bodies and make changes on our end as needed.</p>
                    <p>Upon request, we will delete all of your information from our systems.</p>
                </div>
            </section>

            <section>
                <h2>Our role in GDPR compliance</h2>
                <p>Prestavi acts both as a Data Controller and as a Data Processor.</p>
                <p>As a <strong>Data Controller</strong>, you’re responsible for protecting the data of your employees and customers as they interact directly with services integrated with Prestavi.</p>
                <p>As a <strong>Data Processor</strong>, we are responsible for protecting our customer’s and partner’s data as it moves through our systems. </p>
            </section>

            <section>
                <h2>Your data rights</h2>
                <div>
                    <h3>The right to be informed</h3>
                    <p>You have the right to know what and how we collect and use your data. This privacy policy provides all of the details about that information.</p>
                </div>
                <div>
                    <h3>The right of access</h3>
                    <p>You have the right to access your personal data (commonly referred to as subject access). If you suddenly stop paying for your subscription, we have the right to prevent access to your account until you pay any balance owed.</p>
                </div>
                <div>
                    <h3>The right of rectification</h3>
                    <p>You have the right to have inaccurate personal information be corrected.</p>
                </div>
                <div>
                    <h3>The right of erasure</h3>
                    <p>You have the right to have your personal data erased, known as the Right to be Forgotten, subject to certain limitations under applicable law. If you request that any of your data be deleted, we will effectively close your account and delete all of your data within our possession and, by extension, all of our service providers within thirty days.</p>
                </div>
                <div>
                    <h3>The right to restrict processing</h3>
                    <p>You have the right to request the restriction of your data under certain circumstances, such as to opt-out of our email marketing lists.</p>
                </div>

                <div>
                    <h3>The right to data portability</h3>
                    <p>You have the right to obtain the personal information we have about you and transfer it to another service provider.</p>
                </div>

                <div>
                    <h3>The right to object</h3>
                    <p>You have the right, in certain situations, to object to how or why your personal information is processed.</p>
                </div>
                <div>
                    <h3>The right not to be subjected to automated decision-making</h3>
                    <p>You have the right to object and prevent any decision that could have a significant consequence on you made solely based on an automated process. This right is limited if the decision is necessary for performance of any contract between you and us, is allowable by applicable law, or is based on your explicit consent.</p>
                </div>
                <div>
                    <h3>The right to complain</h3>
                    <p>You have the right to complain to the appropriate supervisory authority regarding the handling of your personal information. If you have a complaint about our use of your information, we would prefer that you to contact us directly in the first instance so that we can address your complaint. If you are in the European Union, you can file a complaint on the EDPS website found <a
                        href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en" target="_blank">here</a>.</p>
                </div>
            </section>
            <section>
                <h2>Data Processing Addendum</h2>
                <p>Our Data Processing Addendum (DPA) sets out the data protection, security, and confidentiality requirements for the processing of personal data needed to provide you with service.</p>
                <a href="https://cdn.prestavi.com/Data+Processing+Addendum.pdf" target="_blank" download>
                    <div className={styles.PDF}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="43.9" height="42.803" viewBox="0 0 43.9 42.803">
                            <g transform="translate(0 -3.2)">
                                <path d="M7.8,3.2H36.1A7.8,7.8,0,0,1,43.9,11V38.2A7.8,7.8,0,0,1,36.1,46H7.8A7.806,7.806,0,0,1,0,38.2V10.982A7.7,7.7,0,0,1,7.8,3.2Z" transform="translate(0 0)" fill="#b30b00"/>
                                <path d="M74.606,66.879c-2.057-2.194-7.662-1.234-9.017-1.1a30.325,30.325,0,0,1-3.823-4.92A21.406,21.406,0,0,0,63,54.3c0-2.057-.823-4.1-3-4.1a2.189,2.189,0,0,0-1.92,1.1c-.96,1.646-.549,4.92.96,8.331a54.348,54.348,0,0,1-3.823,9.017c-2.194.823-6.977,3-7.388,5.468a2.015,2.015,0,0,0,.686,1.92,2.567,2.567,0,0,0,1.92.686c2.863,0,5.742-3.96,7.8-7.525a44.1,44.1,0,0,1,6.84-1.783,10.587,10.587,0,0,0,7.114,3.137c1.92,0,2.606-.823,2.863-1.508A1.95,1.95,0,0,0,74.606,66.879Zm-1.92,1.371c-.137.549-.823,1.1-2.057.823a9.935,9.935,0,0,1-3.96-2.057,18.646,18.646,0,0,1,4.92-.137A1.246,1.246,0,0,1,72.687,68.25Zm-13.268-16.4a.754.754,0,0,1,.686-.411c.686,0,.823.823.823,1.508a19.554,19.554,0,0,1-.96,4.92C58.87,54.863,59.008,52.668,59.419,51.846ZM59.282,67.29a32.735,32.735,0,0,0,1.783-4.371,24.069,24.069,0,0,0,2.468,3.274A20.822,20.822,0,0,0,59.282,67.29Zm-4.645,3.154c-1.9,3-3.685,4.92-4.783,4.92a.824.824,0,0,1-.549-.137.907.907,0,0,1-.137-.823C49.305,73.307,51.5,71.8,54.636,70.444Z" transform="translate(-39.603 -38.943)" fill="#fff"/>
                            </g>
                        </svg>

                        <p>Download Data Processing Addendum</p>
                    </div>
                </a>

            </section>
            <section>
                <h2>Subprocessors</h2>
                <p>Prestavi uses third-party subprocessors, such as cloud computing providers and help desk software, to provide our services. We enter into data processing agreements including GDPR Standard Contractual Clauses with each subprocessor.</p>

                <p><a style={{textDecoration:"underline"}} href="/legal/providers">View our subprocessors</a></p>
            </section>

            <section style={{paddingBottom:"24px"}}>
                <h2>Questions? We have answers.</h2>
                <p>
                    For any questions related to our GDPR compliance, please reach out to us at <span className={styles.Highlight}>gdpr@prestavi.com</span>
                </p>
            </section>
            <Footer />
        </div>
    )
}